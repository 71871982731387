/* html,
body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 16px;
  }

  @media (min-width: 992px) {
    font-size: 18px;
  }
} */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #313131;
  color: wheat;
}
