@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import "./bootstrap_override.scss";
$primary-color: #0f0;
$secondary-color: #ffd700;
$font-family-main: "Roboto", sans-serif;

html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
}

.counter {
  font-size: 4rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

  .steps-number {
    animation: count 1s ease-in-out;
    color: $secondary-color; // Colore oro per il numero dei passi
  }

  @keyframes count {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

// HEADING

.responsive-heading {
  font-family: "Roboto Mono", monospace;
  text-shadow: 0 0 10px $primary-color;
  color: $primary-color;
}

.responsive-subheading {
  font-family: "Roboto Mono", monospace;
  text-shadow: 0 0 5px $primary-color;
  color: $primary-color;
}

/* Stili desktop */
.responsive-heading {
  font-size: 4rem;
}

.responsive-subheading {
  font-size: 1.5rem;
}

/* Per schermi più piccoli di 600px (tipico smartphone) */
@media (max-width: 600px) {
  .responsive-heading {
    font-size: 2.5rem; /* Riduci la dimensione del testo */
  }

  .responsive-subheading {
    font-size: 1rem; /* Riduci anche il sottotitolo */
  }
}

// SOCIAL ICONS

.icon-container {
  position: relative;
  display: inline-block;
  transition: transform 0.2s ease;
}

.icon-container:hover {
  transform: scale(1.1);
}

.icon-container::before,
.icon-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $primary-color;
  opacity: 0;
  z-index: 1;
  pointer-events: none;
  mix-blend-mode: difference;
}

.icon-container:hover::before {
  animation: glitch 0.25s infinite linear alternate-reverse;
}

.icon-container:hover::after {
  animation: glitch2 0.35s infinite linear alternate-reverse;
}

.icon-container .hover-icon {
  display: none;
}

.icon-container:hover .default-icon {
  display: none;
}

.icon-container:hover .hover-icon {
  display: block;
}

.matrix-glitch {
  position: relative;
  animation: glitch 1.5s infinite;
}

@keyframes glitch {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  50% {
    opacity: 1;
    transform: translate(2px, -2px);
  }
  100% {
    opacity: 0;
    transform: translate(-2px, 2px);
  }
}

@keyframes glitch2 {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  50% {
    opacity: 1;
    transform: translate(-2px, 2px);
  }
  100% {
    opacity: 0;
    transform: translate(2px, -2px);
  }
}

// Loading Screen SCSS

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 4rem;
  z-index: 999;
  display: grid;
  place-items: center;
  background-color: rgb(0, 0, 0);
  text-align: center;
}

.loading-screen.hidden {
  animation: fade-out 0.5s ease-in-out forwards 1s;
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}

.loading-screen-text {
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  font-weight: 900;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin: 0;
}

.progress_container {
  width: 100%;
  height: 1rem;
  background-color: #8b8b8b7e;
  border-radius: 0.25rem;
  overflow: hidden;
  position: relative;
}

.progress_bar {
  width: 0;
  height: 100%;
  background-color: $primary-color;
  transition: width 0.5s ease-in-out;
}
